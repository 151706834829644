import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import moment from 'moment';
import chroma from 'chroma-js';
import _ from 'lodash';
import GeoJSON from 'geojson';
import 'moment/locale/es';
import bbox from '@turf/bbox';
import midpoint from '@turf/midpoint';
import intersect from '@turf/intersect';
import { point } from '@turf/helpers';
import Logo from '../assets/logo-dark.svg';

function Surveillance() {
	const [map, setMap] = useState(null);
	const [loading, setLoading] = useState(true);
	const [classes, setClasses] = useState(null);
	const [zones, setZones] = useState(null);
	const [near, setNear] = useState([]);
	const [address, setAddress] = useState(null);
	const [collapsed, setCollapsed] = useState(true);
	const [clusters, setClusters] = useState(null);
	const mapContainer = useRef(null);

	const colorScale = [
		'#FFF28B',
		'#DAA613',
		'#D45313',
		'#9D1319',
		'#780C22',
		'#CCC',
	];

	useEffect(() => {
		fetch('/api/polygons')
			.then((response) => response.json())
			.then((json) => {
        console.log(json)
				Object.keys(json[0]).forEach(function (item) {
					const ft = JSON.parse(json[0][item]);
					setClusters(ft);
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (map && clusters) {
			fetch('/api/clusters')
				.then((response) => response.json())
				.then((json) => {
					_.forEach(clusters.features, (ft) => {
						const filter = _.filter(json, (j) => {
							return j.zone === ft.properties.zone;
						});

						if (filter.length) {
							ft.properties.average = filter[0].average;
							ft.properties.count = filter[0].count;
							ft.properties.sum = filter[0].sum;
							ft.properties.max = filter[0].max;
							ft.properties.min = filter[0].min;
						}
					});

					const scale = [
						'#FFF28B',
						'#DAA613',
						'#D45313',
						'#9D1319',
						'#780C22',
						'#CCC',
					];

					setZones(clusters);

					map.getSource('clusters').setData(clusters);

					const array = json.map((e) => {
						return e['average'];
					});
					const limits = chroma.limits(array, 'q', 4);

					setClasses(limits);

					const data = json.map((e) => {
						const id = e['zone'];
						let color;

						for (let i = 0; i < limits.length; i++) {
							if (e.average <= limits[i]) {
								color = scale[i];
								break;
							}
						}
						return [id, color];
					});

					map.setPaintProperty('clusters', 'fill-color', {
						property: 'zone',
						type: 'categorical',
						stops: data,
						default: '#ccc',
					});

					setLoading(false);

					map.easeTo({
						zoom: 8.5,
						speed: 0.2,
						curve: 1,
						easing: (t) => {
							return t;
						},
						essential: true,
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [map, clusters]);

	useEffect(() => {
		const initializeMap = ({ setMap, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/prvcu/ckb0xhkvc0lzx1itaze8gephy',
				center: [-66.391182, 18.220833],
				zoom: 9.5,
				maxZoom: 13.5,
				pitchWithRotate: false,
				attributionControl: false,
				boxZoom: true,
				dragRotate: false,
				logoPosition: 'bottom-right',
				accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
			});

			map.on('load', () => {
				setMap(map);
				map.resize();

				map.addSource('buffer', {
					type: 'geojson',
					data: null,
				});

				map.addSource('length', {
					type: 'geojson',
					data: null,
				});

				map.addSource('midpoint', {
					type: 'geojson',
					data: null,
				});

				map.addSource('centroid', {
					type: 'geojson',
					data: null,
				});

				map.addSource('clusters', {
					type: 'geojson',
					data: null,
				});

				map.addSource('dengue', {
					type: 'geojson',
					data: null,
				});

				map.addSource('zika', {
					type: 'geojson',
					data: null,
				});

				map.addSource('chinkungunya', {
					type: 'geojson',
					data: null,
				});

				map.addLayer(
					{
						id: 'clusters',
						type: 'fill',
						source: 'clusters',
					},
					'settlement-subdivision-label'
				);

				map.addLayer(
					{
						id: 'clusters-borders',
						type: 'line',
						source: 'clusters',
						paint: {
							'line-color': '#ffffff',
							'line-width': 1,
							'line-opacity': 0.25,
						},
					},
					'settlement-subdivision-label'
				);

				map.addLayer({
					id: 'buffer',
					type: 'fill',
					source: 'buffer',
					paint: {
						'fill-opacity': 0.15,
						'fill-color': '#00b1ff',
					},
				});

				map.addLayer({
					id: 'buffer-border',
					type: 'line',
					source: 'buffer',
					paint: {
						'line-width': 2,
						'line-color': '#00b1ff',
						'line-dasharray': [2, 2],
					},
				});

				map.addLayer({
					id: 'length',
					type: 'line',
					source: 'length',
					paint: {
						'line-width': 2,
						'line-color': '#00b1ff',
						'line-dasharray': [2, 2],
					},
				});

				map.addLayer({
					id: 'clusters-labels',
					type: 'symbol',
					source: 'clusters',
					paint: {
						'text-color': '#ffffff',
						'text-halo-color': '#000000',
						'text-halo-width': 0.5,
						'text-halo-blur': 0.25,
					},
					layout: {
						'text-size': 12,
						'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
						'text-field': ['get', 'name'],
					},
				});

				map.addLayer({
					id: 'centroid',
					type: 'circle',
					source: 'centroid',
					paint: {
						'circle-color': '#00b1ff',
						'circle-radius': 8,
						'circle-stroke-color': '#ffffff',
						'circle-stroke-width': 3,
					},
				});

				map.addLayer({
					id: 'midpoint',
					type: 'symbol',
					source: 'midpoint',
					paint: {
						'text-color': '#00b1ff',
					},
					layout: {
						'text-size': 13,
						'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
						'text-field': ['get', 'length'],
						'text-offset': [0, -1],
					},
				});

				map.addLayer({
					id: 'dengue',
					type: 'circle',
					source: 'dengue',
					paint: {
						'circle-radius': 12,
						'circle-opacity': 1,
						'circle-color': '#ffa950',
						'circle-stroke-width': 3,
						'circle-stroke-color': '#ffffff',
					},
				});

				map.addLayer({
					id: 'dengue-label',
					type: 'symbol',
					source: 'dengue',
					layout: {
						'text-font': ['Open Sans Semibold'],
						'text-field': '{count}',
						'text-size': 12,
						'text-allow-overlap': true,
						'text-max-width': 4,
					},
					paint: {
						'text-color': '#ffffff',
					},
				});

				map.addLayer({
					id: 'zika',
					type: 'circle',
					source: 'zika',
					paint: {
						'circle-radius': 12,
						'circle-opacity': 1,
						'circle-color': '#72c781',
						'circle-stroke-width': 3,
						'circle-stroke-color': '#ffffff',
					},
				});

				map.addLayer({
					id: 'zika-label',
					type: 'symbol',
					source: 'zika',
					layout: {
						'text-font': ['Open Sans Semibold'],
						'text-field': '{count}',
						'text-size': 12,
						'text-allow-overlap': true,
						'text-max-width': 4,
					},
					paint: {
						'text-color': '#ffffff',
					},
				});

				map.addLayer({
					id: 'chinkungunya',
					type: 'circle',
					source: 'chinkungunya',
					paint: {
						'circle-radius': 12,
						'circle-opacity': 1,
						'circle-color': '#c299e3',
						'circle-stroke-width': 3,
						'circle-stroke-color': '#ffffff',
					},
				});

				map.addLayer({
					id: 'chinkungunya-label',
					type: 'symbol',
					source: 'chinkungunya',
					layout: {
						'text-font': ['Open Sans Semibold'],
						'text-field': '{count}',
						'text-size': 12,
						'text-allow-overlap': true,
						'text-max-width': 4,
					},
					paint: {
						'text-color': '#ffffff',
					},
				});

				map.on('mouseenter', 'clusters', () => {
					map.getCanvas().style.cursor = 'pointer';
				});

				map.on('mouseleave', 'clusters', () => {
					map.getCanvas().style.cursor = '';
				});

				map.on('click', (e) => {
					const features = map.queryRenderedFeatures(e.point, {
						layers: ['clusters', 'dengue', 'zika', 'chinkungunya'],
					});

					if (features.length) {
						map.getCanvas().style.cursor = 'pointer';
						const source = features[0].source;
						if (source === 'clusters') {
							let html = '<div>';
							html += `<div class="txt-s color-gray txt-bold">Vigilancia del mosquito hembra <em>Aedes aegypti</em></div>`;
							html += `<div class="txt-bold">${features[0].properties.name} (${features[0].properties.zone})</div>`;
							html += `<div class="txt-normal color-gray">Promedio mosquitos por trampa/semana: <span class="txt-bold">${features[0].properties.average}</span></div>`;
							html += `<div class="txt-normal color-gray">Total de mosquitos capturados: <span class="txt-bold">${features[0].properties.sum}</span></div>`;
							html += `<div class="txt-normal color-gray">Cantidad mínima de mosquitos: <span class="txt-bold">${features[0].properties.min}</span></div>`;
							html += `<div class="txt-normal color-gray">Cantidad máxima de mosquitos: <span class="txt-bold">${features[0].properties.max}</span></div>`;
							html += `<div class="txt-normal color-gray">Total de trampas: <span class="txt-bold">${features[0].properties.count}</span></div>`;
							html += '</div>';

							new mapboxgl.Popup({
								maxWidth: 350,
							})
								.setLngLat([e.lngLat.lng, e.lngLat.lat])
								.setHTML(html)
								.addTo(map);
						} else {
							let html = '<div>';
							html += `<div class="txt-s color-gray txt-bold">Muestras de Mosquitos Positivos a ${_.upperFirst(
								source
							)}</div>`;
							html += `<div class="txt-bold txt-s">${features[0].properties.name} (${features[0].properties.zone})</div>`;
							html += `<div class="txt-normal color-gray">Total de muestras positivas<span class="color-red">*</span>: <span class="txt-bold">${features[0].properties.count}</span></div>`;
							html += `<div class="txt-normal color-gray">Fecha de última muestra positiva: <span class="txt-bold">${moment(
								features[0].properties.date
							).format('DD/MM/YYYY')}</span></div>`;
							html += `<div class="txt-xs color-gray txt-em w240"><span class="color-red">*</span> Un resultado positivo se compone de una muestra que contiene de 5-20 mosquitos Aedes aegypti hembra en una trampa de vigilancia AGO.</div>`;
							html += '</div>';

							new mapboxgl.Popup({
								maxWidth: 350,
							})
								.setLngLat(e.lngLat)
								.setHTML(html)
								.addTo(map);
						}
					}
				});
			});
		};

		if (!map) initializeMap({ setMap, mapContainer });
	}, [map]);

	useEffect(() => {
		if (map) {
			fetch('/api/dengue')
				.then((response) => response.json())
				.then((json) => {
					if (json.length) {
						const geojson = GeoJSON.parse(json, { Point: ['y', 'x'] });
						map.getSource('dengue').setData(geojson);
					}
				})
				.catch((error) => {
					console.log(error);
				});

			fetch('/api/zika')
				.then((response) => response.json())
				.then((json) => {
					if (json.length) {
						const geojson = GeoJSON.parse(json, { Point: ['y', 'x'] });
						map.getSource('zika').setData(geojson);
					}
				})
				.catch((error) => {
					console.log(error);
				});

			fetch('/api/chikungunya')
				.then((response) => response.json())
				.then((json) => {
					if (json.length) {
						const geojson = GeoJSON.parse(json, { Point: ['y', 'x'] });
						map.getSource('chikungunya').setData(geojson);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [map]);

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setCollapsed(false);

				const bufferGeoJSON = createGeoJSONCircle(
					[position.coords.longitude, position.coords.latitude],
					1.6
				);

				const bounds = bbox(bufferGeoJSON);

				map.fitBounds(bounds, {
					padding: { top: 100, bottom: 100, left: 100, right: 100 },
				});

				fetch(
					`https://api.mapbox.com/geocoding/v5/mapbox.places/${[
						position.coords.longitude,
						position.coords.latitude,
					]}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
				)
					.then((response) => response.json())
					.then((json) => setAddress(json.features[0].place_name))
					.catch((err) => console.log(err));

				const point1 = point([
					position.coords.longitude,
					position.coords.latitude,
				]);
				const point2 = point(bufferGeoJSON.geometry.coordinates[0][0]);

				const mid = midpoint(point1, point2);
				mid.properties.length = '1 Milla';

				const line = {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							properties: {
								length: '1 milla',
							},
							geometry: {
								type: 'LineString',
								coordinates: [
									[position.coords.longitude, position.coords.latitude],
									bufferGeoJSON.geometry.coordinates[0][0],
								],
							},
						},
					],
				};

				_.forEach(zones.features, (ft) => {
					const polygon = intersect(ft, bufferGeoJSON);
					if (polygon) {
						setNear([...near, ft.properties]);
					}
				});

				map.getSource('buffer').setData(bufferGeoJSON);
				map.getSource('length').setData(line);
				map.getSource('midpoint').setData(mid);
				map.getSource('centroid').setData(point1);
			});
		}
	};

	const createGeoJSONCircle = (center, radiusInKm, points = 64) => {
		const coords = {
			latitude: center[1],
			longitude: center[0],
		};
		const km = radiusInKm;
		const ret = [];
		const distanceX =
			km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
		const distanceY = km / 110.574;
		let theta;
		let x;
		let y;
		for (let i = 0; i < points; i += 1) {
			theta = (i / points) * (2 * Math.PI);
			x = distanceX * Math.cos(theta);
			y = distanceY * Math.sin(theta);
			ret.push([coords.longitude + x, coords.latitude + y]);
		}
		ret.push(ret[0]);
		return {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [ret],
			},
		};
	};

	return (
		<>
			{loading && (
				<div className="absolute z5 viewport-full bg-gray-dark w-full flex-parent flex-parent--center-cross flex-parent--center-main">
					<div className="flex-child">
						<div className="loading loading--dark mb12" />
						<div className="txt-bold txt-xs color-gray-light">
							Cargando Datos...
						</div>
					</div>
				</div>
			)}

			<div className="flex-parent viewport-full relative scroll-hidden">
				<div className="flex-child w240-ml absolute wmin300-ml static-ml left bottom">
					<div className="flex-parent flex-parent--column viewport-half viewport-full-ml bg-white scroll-auto scroll-styled">
						<div className="flex-child py24">
							<img alt="logo" src={Logo} className="pl60 py6 none block-ml" />
							<div className="txt-h4 txt-bold align-center px12 py6">
								Vigilancia semanal del mosquito hembra <em>Aedes aegypti</em>
							</div>
							<div className="py12 px36 align-center">
								{` ${moment()
									.locale('es')
									.subtract(2, 'weeks')
									.weekday(6)
									.format('LL')} - ${moment()
									.locale('es')
									.subtract(1, 'weeks')
									.weekday(5)
									.format('LL')}`}
							</div>
							<div className="block mb6 align-center">
								¡Protégete, usa repelente y vacía los criaderos de mosquitos!
							</div>
							<div className="txt-s py12 px12 flex-parent flex-parent--column align-center">
								<div className="select-container mb12">
									<select
										className="select txt-s"
										onChange={(e) => {
											const feature = _.filter(zones.features, (ft) => {
												return ft.properties.zone === e.target.value;
											});

											const bounds = bbox(feature[0]);

											map.fitBounds(bounds, {
												padding: 20,
											});
										}}
									>
										<option>Navegar a zona de interés</option>
										{zones &&
											_.map(zones.features, (ft) => {
												return (
													<option
														key={ft.properties.id}
														value={ft.properties.zone}
													>
														{ft.properties.name} ({ft.properties.zone})
													</option>
												);
											})}
									</select>
									<div className="select-arrow" />
								</div>
								<button className="btn round" onClick={getLocation}>
									¿Hay mosquitos cerca de mí?
								</button>
							</div>
						</div>
						<div className="flex-child flex-child--grow bg-gray-faint txt-s ">
							<div className="px12 py12 color-darken75">
								<div className="block txt-bold mb3">Leyenda</div>
								<span className="block txt-bold mb6">
									Promedio de mosquitos
								</span>
								<span className="block mb6">
									Promedio semanal de mosquitos <em>Aedes aegypti</em> hembra
								</span>
								<div className="grid mb6">
									<div
										className="col h12 border border--darken10 border-r--0"
										style={{ backgroundColor: colorScale[5] }}
									/>
									<div
										className="col h12 border border--darken10 border-r--0"
										style={{ backgroundColor: colorScale[0] }}
									/>
									<div
										className="col h12 border border--darken10 border-r--0"
										style={{ backgroundColor: colorScale[1] }}
									/>
									<div
										className="col h12 border border--darken10 border-r--0"
										style={{ backgroundColor: colorScale[2] }}
									/>
									<div
										className="col h12 border border--darken10 border-r--0"
										style={{ backgroundColor: colorScale[3] }}
									/>
									<div
										className="col h12 border border--darken10"
										style={{ backgroundColor: colorScale[4] }}
									/>
								</div>
								<div className="grid txt-xs mb12">
									<div className="col align-center">Sin Datos</div>
									<div className="col align-center">
										{classes && classes[0]}
									</div>
									<div className="col align-center">
										{classes && classes[1]}
									</div>
									<div className="col align-center">
										{classes && classes[2]}
									</div>
									<div className="col align-center">
										{classes && classes[3]}
									</div>
									<div className="col align-center">
										{classes && classes[4]}+
									</div>
								</div>
								<div className="block txt-bold mb6">Zonas Positivas</div>
								<div className="block mb6">
									Total de trampas que dieron positivo a algún virus desde enero
									2020
								</div>
								<div className="flex-parent flex-parent--center-cross mb6">
									<div className="flex-parent flex-parent--center-cross flex-parent--center-main round-full h18 w18 bg-orange-light color-white txt-bold txt-xs mr6">
										#
									</div>
									<div className="flex-child">Positivo a Dengue</div>
								</div>
								<div className="flex-parent flex-parent--center-cross mb6">
									<div className="flex-parent flex-parent--center-cross flex-parent--center-main round-full h18 w18 bg-green-light color-white txt-bold txt-xs mr6">
										#
									</div>
									<div className="flex-child">Positivo a Zika</div>
								</div>
								<div className="flex-parent flex-parent--center-cross mb12">
									<div className="flex-parent flex-parent--center-cross flex-parent--center-main round-full h18 w18 bg-purple-light color-white txt-bold txt-xs mr6">
										#
									</div>
									<div className="flex-child">Positivo a Chikungunya</div>
								</div>
								<div className="txt-s txt-bold mb3">Capas</div>
								<label className="switch-container flex-parent flex-parent--center-cross mb6">
									<input
										type="checkbox"
										defaultChecked
										onChange={(e) => {
											if (e.target.checked) {
												map.setLayoutProperty(
													'clusters',
													'visibility',
													'visible'
												);
											} else {
												map.setLayoutProperty('clusters', 'visibility', 'none');
											}
										}}
									/>
									<div className="switch mr6 flex-child" />
									<div className="flex-child mt6">Promedio de mosquitos</div>
								</label>
								<label className="switch-container flex-parent flex-parent--center-cross mb6">
									<input
										type="checkbox"
										defaultChecked
										onChange={(e) => {
											if (e.target.checked) {
												map.setLayoutProperty(
													'dengue',
													'visibility',
													'visible'
												);
												map.setLayoutProperty(
													'dengue-label',
													'visibility',
													'visible'
												);
											} else {
												map.setLayoutProperty('dengue', 'visibility', 'none');
												map.setLayoutProperty(
													'dengue-label',
													'visibility',
													'none'
												);
											}
										}}
									/>
									<div className="switch mr6 flex-child" />
									<div className="flex-child mt6">Positivo a Dengue</div>
								</label>
								<label className="switch-container flex-parent flex-parent--center-cross mb6">
									<input
										type="checkbox"
										defaultChecked
										onChange={(e) => {
											if (e.target.checked) {
												map.setLayoutProperty('zika', 'visibility', 'visible');
												map.setLayoutProperty(
													'zika-label',
													'visibility',
													'visible'
												);
											} else {
												map.setLayoutProperty('zika', 'visibility', 'none');
												map.setLayoutProperty(
													'zika-label',
													'visibility',
													'none'
												);
											}
										}}
									/>
									<div className="switch mr6 flex-child" />
									<div className="flex-child mt6">Positivo a Zika</div>
								</label>
								<label className="switch-container flex-parent flex-parent--center-cross mb12">
									<input
										type="checkbox"
										defaultChecked
										onChange={(e) => {
											if (e.target.checked) {
												map.setLayoutProperty(
													'chinkungunya',
													'visibility',
													'visible'
												);
												map.setLayoutProperty(
													'chinkungunya-label',
													'visibility',
													'visible'
												);
											} else {
												map.setLayoutProperty(
													'chinkungunya',
													'visibility',
													'none'
												);
												map.setLayoutProperty(
													'chinkungunya-label',
													'visibility',
													'none'
												);
											}
										}}
									/>
									<div className="switch mr6 flex-child" />
									<div className="flex-child mt6">Positivo a Chikungunya</div>
								</label>
								<div className="txt-xs txt-em color-darken50">
									Fuente: Unidad de Control de Vectores de Puerto Rico{' '}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					ref={(el) => (mapContainer.current = el)}
					className="flex-child flex-child--grow bg-darken10 viewport-half viewport-full-ml"
				>
					<div
						className={`bottom-bar flex-parent flex-parent--center-main absolute bottom z3 w-full h240 ${
							collapsed ? 'collapsed' : ''
						}`}
					>
						<div className="txt-s color-lighten75 bg-gray-dark  round-t w-full w600-ml h240">
							<div className="flex-parent flex-parent--center-main flex-parent--space-between-main flex-parent--center-cross px12 h60 round-t border-b border--lighten10">
								<div className="flex-child txt-bold">
									<div className="block">
										Mosquitos cerca de mí (radio de 1 milla)
									</div>
									{address && (
										<div className="block txt-normal txt-s">
											Cerca de: {address}
										</div>
									)}
								</div>
								<div className="flex-child">
									<button
										onClick={() => {
											setCollapsed(true);
											setNear([]);
											setAddress(null);
											map.getSource('buffer').setData({
												type: 'FeatureCollection',
												features: [],
											});
											map.getSource('length').setData({
												type: 'FeatureCollection',
												features: [],
											});
											map.getSource('midpoint').setData({
												type: 'FeatureCollection',
												features: [],
											});
											map.getSource('centroid').setData({
												type: 'FeatureCollection',
												features: [],
											});
											setNear([]);
										}}
									>
										<svg className="icon link color-lighten50 color-lighten75-on-hover txt-bold">
											<use xlinkHref="#icon-close" />
										</svg>
									</button>
								</div>
							</div>
							<div className="scroll-auto scroll-styled--dark h180">
								{near.length > 0 ? (
									<div>
										{_.map(near, (n) => {
											return (
												<div
													key={n.id}
													className="py12 px12 border-b border--lighten10 mb12 txt-bold"
												>
													<div className="txt-m">{n.name}</div>
													<div>Promedio semanal de mosquitos: {n.average}</div>
													<div>Total de mosquitos en la zona: {n.sum}</div>
													<div>Total de trampas en la zona: {n.count}</div>
												</div>
											);
										})}
									</div>
								) : (
									<div className="px12 py12 txt-bold align-center">
										¡Lo sentimos, No encontramos datos cerca de usted!
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="absolute z1 right mx12 my12">
						<button
							className="btn btn--s round mr6"
							onClick={() => {
								map.easeTo({
									zoom: map.getZoom() + 0.5,
								});
							}}
						>
							<svg className="icon h18 w18">
								<use xlinkHref="#icon-plus" />
							</svg>
						</button>
						<button
							className="btn btn--s round mr6"
							onClick={() => {
								map.easeTo({
									zoom: map.getZoom() - 0.5,
								});
							}}
						>
							<svg className="icon h18 w18">
								<use xlinkHref="#icon-minus" />
							</svg>
						</button>
						<button
							className="btn btn--s round mr6"
							onClick={() => {
								map.easeTo({
									center: [-66.391182, 18.220833],
									zoom: 9.5,
								});
							}}
						>
							<svg className="icon h18 w18">
								<use xlinkHref="#icon-viewport" />
							</svg>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Surveillance;
