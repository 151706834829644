import React from 'react';
import Surveillance from './Surveillance';

function App() {
	return (
		<>
			<Surveillance />
		</>
	);
}

export default App;
